<div *ngIf="!isLoading; else loading" class="sticky-table">
  <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource.data.length > 0">

    <!-- Mass Email Selector Column -->
    <ng-container matColumnDef="emailSelect">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [disabled]="disableCheckBoxes()" *ngIf="canEmail">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [disabled]="disableCheckBoxes()" *ngIf="canEmail">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Onliner Column -->
    <ng-container matColumnDef="onlinerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
      <td mat-cell class="text-left" *matCellDef="let review" >
        {{ onlinerDisplay(review.onlinerEmployee) }}
      </td>
    </ng-container>

    <!-- Reviewer Column -->
    <ng-container matColumnDef="reviewerName">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Reviewer</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ onlinerDisplay(review.reviewerEmployee) }}</td>
    </ng-container>

    <!-- Practice Column -->
    <ng-container matColumnDef="practiceName">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Practice</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ practiceDisplay(review) }}</td>
    </ng-container>

    <!-- Service Column -->
    <ng-container matColumnDef="serviceName">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Service</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ serviceDisplay(review) }}</td>
    </ng-container>

    <!-- Consultant Level Column -->
    <ng-container matColumnDef="consultantLevel">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Consultant Level</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ consultantLevelDisplay(review) }}</td>
    </ng-container>

    <!-- Primary Competency Column -->
    <ng-container matColumnDef="primaryCompetency">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Primary Competency</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ primaryCompetencyDisplay(review) }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ statusDisplay(review) }}
        <span *ngIf="loadingForm"><i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
      </td>
    </ng-container>

    <!-- Last Status Changed Date Column -->
    <ng-container matColumnDef="lastStatusChanged">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Last Status Changed Date</th>
      <td mat-cell class="text-center" *matCellDef="let review">{{ lastStatusDateDisplay(review) }}
    </ng-container>

    <!-- Number of Days since Last Update Column -->
    <ng-container matColumnDef="daysSinceStatusChange">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Number of Days since Last Update</th>
      <td mat-cell class="text-center" *matCellDef="let review">{{ daysSinceLastUpdateDisplay(review) }}
    </ng-container>

    <!-- BCC All Column -->
    <ng-container matColumnDef="bccAll" *ngIf="bccView">
      <th mat-header-cell class="text-left" *matHeaderCellDef>BCC</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ bccAllDisplay(review) }}</td>
    </ng-container>

    <!-- Action (email) Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let review" class="leftAlignCell" (click)="openEmailForm(review, $event); clicked=true">
        <span *ngIf="isSending && currReview == review"><i class="fa fa-spinner fa-spin"
            aria-hidden="false"></i></span><button class="btn btn-link control" *ngIf="canEmail"
          matTooltip="Send Email"><i class="fas fa-envelope"></i></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders; sticky: true" [attr.] ></tr>
    <tr mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeaders" [ngClass]="{ 'row-hide': row.compensationReviewId == 0}" style="cursor: pointer"
      (click)="finishedRefreshing && !clicked && openForm(row)">
    </tr>
  </table>
  <div *ngIf="dataSource.data.length === 0">No reviews found</div>

</div>
<div class="m-2 sticky-footer" *ngIf="canEmail">
  <button mat-stroked-button class="submit-btn" (click)="openMassEmailForm($event); clicked=true"
    [disabled]="!selection.hasValue() || isSending">
    <span *ngIf="isSending"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>Email Selected
  </button>
</div>

<ng-template #loading>
  <div class="loading m-auto"></div>
</ng-template>
