<div (mouseenter)="collapseOpenSidenav()"
     (mouseleave)="collapseCloseSidenav()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <img *ngIf="(collapsedOpen$ | async) || !collapsed" [src]="imageUrl$ | async" alt="Logo" class="select-none pointer-events-none flex-none"/>
      <img *ngIf="!(collapsedOpen$ | async) && collapsed" [src]="imgSrc$ | async" alt="Icon" class="select-none pointer-events-none flex-none"/>
      <h2 class="vex-sidenav-toolbar__headline flex-auto">{{ title$ | async }}</h2>
      <button (click)="toggleCollapse()"
              *ngIf="showCollapsePin$ | async"
              class="-mr-4 leading-none flex-none hidden lg:block"
              mat-icon-button
              type="button">
        <mat-icon *ngIf="!collapsed"
                  class="icon-xs"
                  svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed"
                  class="icon-xs"
                  svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button>
    </div>

    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
      <div class="vex-sidenav-search relative"
           (click)="openSearch()"
           matRipple
           matRippleColor="var(--sidenav-item-ripple-color)">
        <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
        <div class="flex-auto vex-sidenav-search__placeholder">Quick Search</div>
        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div>

    <!-- <div class="vex-sidenav-toolbar__divider"></div> -->
  </div>

  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <ng-container *ngFor="let item of items; trackBy: trackByRoute">
      <vex-sidenav-item [item]="item" [itemVisibility]="itemVisibility"
                        [level]="0"></vex-sidenav-item>
      </ng-container>
    </div>

  </vex-scrollbar>

  <div *ngIf="userVisible$ | async"
       class="vex-sidenav-user__container flex-none">
 
  </div>
</div>
