<div *ngIf="!isSearching;else loading"></div>



<div *ngIf="showTable && !isSearching" class="table-custom">

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="effDate">
      <th mat-header-cell *matHeaderCellDef >Effective Date</th>
      <td mat-cell *matCellDef="let compReview" class="leftAlignCell">
        {{ effectiveDateDisplay(compReview) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="prevCompensation">
      <th mat-header-cell class="text-center" *matHeaderCellDef >Previous</th>
      <td mat-cell *matCellDef="let compReview">{{ prevCompDisplay(compReview) }}</td>
    </ng-container>
    <ng-container matColumnDef="newCompensation">
      <th mat-header-cell class="text-center" *matHeaderCellDef >New</th>
      <td mat-cell *matCellDef="let compReview">{{ newCompDisplay(compReview) }}</td>
    </ng-container>

    <ng-container matColumnDef="increase">
      <th mat-header-cell class="text-center" *matHeaderCellDef >Increase</th>
      <td mat-cell *matCellDef="let compReview; let ix = index">{{ increaseDisplay(compReview) }}</td>
    </ng-container>

    <ng-container matColumnDef="vacationEffectiveDate">
      <th mat-header-cell *matHeaderCellDef >Effective Date</th>
      <td mat-cell *matCellDef="let compReview" class="leftAlignCell">
        {{ effectiveDateDisplay(compReview, true) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="prevVacationDays">
      <th mat-header-cell class="text-center" *matHeaderCellDef >Previous Weeks</th>
      <td mat-cell class="text-center" *matCellDef="let ix = index">{{ prevVacationDisplay(ix) }}</td>
    </ng-container>

    <ng-container matColumnDef="newVacationDays">
      <th mat-header-cell class="text-center" *matHeaderCellDef >New Weeks</th>
      <td mat-cell class="text-center" *matCellDef="let compReview">{{ vacationDisplay(compReview) }}</td>
    </ng-container>

    <ng-container matColumnDef="statusName">
      <th mat-header-cell class="text-center" *matHeaderCellDef ></th>
      <td mat-cell class="text-center" *matCellDef="let compReview" class="leftAlignCell">
        {{ statusNameDisplay(compReview) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let compreview" class="leftAlignCell"
        (click)="openDeleteConfirmation(compreview, $event)">
        <div *ngIf="allowDelete(compreview)">
          <span *ngIf="isDeleting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
          <button *ngIf="!isDeleting" matTooltip="Remove Form" class="btn btn-link control"><i class="fas fa-trash-alt"></i></button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="compensation-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">Compensation</th>
    </ng-container>
    <ng-container matColumnDef="vacation-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Vacation</th>
    </ng-container>
    <ng-container matColumnDef="status-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Status</th>
    </ng-container>
    <ng-container matColumnDef="no-group">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['compensation-group', 'vacation-group', 'status-group', 'no-group']"
      class="custom-header-row">
    </tr>
    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
    <div *ngIf="clickable else nonClickTable">
      <tr mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeaders" [ngClass]="{ 'row-hide': row.compensationReviewId == 0 || (isHistoryPage && isRejected(row))}" style="cursor: pointer"
        (click)="!clicked && openForm(row)"></tr>
    </div>
    <ng-template #nonClickTable>
      <tr mat-row #nonClickTable class="mat-row" *matRowDef="let row; columns: resultColumnHeaders" [ngClass]="{ 'row-hide': row.compensationReviewId == 0 || (isHistoryPage && isRejected(row))}"></tr>
    </ng-template>

  </table>
</div>

<ng-template #loading>
  <div class="loading m-auto"></div>
</ng-template>
