<div mat-dialog-title class="m-2">
  <h2 class="page-title d-inline-block m-2">Compensation Review{{ force0 }}</h2>
  <button mat-mini-fab mat-dialog-close="cancel" type="button" aria-label="Close">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="div-content m-0 comp-form-content " [ngClass]="{'no-actions': !canReject && !canSubmit && !canSave}">
  <form *ngIf="compensationForm && currentReview; else loading" [formGroup]="compensationForm" novalidate>
    <div class="form-group row m-0 mt-2">
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="custom-outline-mat-form-field" >
          <mat-label>Onliner</mat-label>
          <input matInput type="text" placeholder="Onliner" aria-label="Onliner" readonly
                 [value]="getOnlinerDisplay()">
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="custom-outline-mat-form-field" >
          <mat-label>Status</mat-label>
          <input matInput type="text" placeholder="Status" aria-label="Status" readonly
                 [value]="currentReview.statusType.statusTypeName">
        </mat-form-field>
      </div>
      <div class="col-sm-6" [formGroup]="compensationForm.controls['header']">
        <mat-form-field appearance="outline" [ngClass]="{'editable': initiatorEditable }" class="onliner custom-outline-mat-form-field">
          <mat-label>Reviewer</mat-label>
          <input matInput type="text" placeholder="Reviewer" aria-label="Reviewer" formControlName="reviewer"
                 [matAutocomplete]="reviewerauto" [errorStateMatcher]="errorMatcher">
          <mat-error *ngIf="showError('header', 'reviewer')">Please enter the name of the Reviewer.</mat-error>

          <mat-autocomplete autoActiveFirstOption #reviewerauto="matAutocomplete" panelWidth="280px"
                            [displayWith]="reviewerDisplay">
            <mat-option class="options" *ngFor="let option of filteredReviewers | async" [value]="option">
              {{ reviewerDisplay(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row m-0">
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="custom-outline-mat-form-field">
          <mat-label>Anniversary</mat-label>
          <input matInput type="text" placeholder="Anniversary" aria-label="Anniversary" readonly
                 [value]="currentReview.anniversaryDate | date : 'MM/dd/yyyy'">
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="custom-outline-mat-form-field">
          <mat-label>Start Date</mat-label>
          <input matInput type="text" placeholder="Start Date" aria-label="Start Date" readonly
                 [value]="currentReview.startDate | date : 'MM/dd/yyyy'">
        </mat-form-field>
      </div>
    </div>


    <h5 class="subsection">Compensation</h5>
    <div class="mat-elevation-z8 text-center">
      <div class="tableDesktop">
        <div class="row header-row">
          <div class="col-md-3"> Effective Date </div>
          <div class="col-md-3"> Previous </div>
          <div class="col-md-3"> New </div>
          <div class="col-md-3"> Increase </div>

        </div>
        <div *ngFor="let compensation of compensationForm.controls['compensations'].controls; first as isCurrent">
          <div class="row body-row" [formGroup]="compensation" *ngIf="seeBothRows || (isCurrent && onlinerView)">
           <div class="col-md-3">
            <mat-form-field appearance="outline" class="custom-outline-mat-form-field" floatLabel="never" [ngClass]="{'editable': (!locked && isCurrent) || isCurrent && effDateUnlocked}">
              <input matInput class="text-center"
                     formControlName="effectiveDate"
                     [min]="minCompEffectiveDate"
                     [matDatepicker]="compEffectiveDatePicker"
                     name="effectiveDate"
                     aria-label="Effective Date"
                     placeholder="MM/dd/yyyy"
                     (click)="compEffectiveDatePicker.open();"
                     (focus)="compEffectiveDatePicker.open()"
                     minDateRangeValidator="minCompEffectiveDate">
              <mat-datepicker #compEffectiveDatePicker></mat-datepicker>
              <mat-error *ngIf="isCurrent && showDateError(compensation,'effectiveDate')">
                Required (format 'MM/d/yyyy')
              </mat-error>
              <mat-error *ngIf="isCurrent && invalidEffectiveDate(compensation,'effectiveDate', true)">
                Effective Date must be after previous Effective Date or Start Date.
              </mat-error>
            </mat-form-field>
            </div> 
            <div class="col-md-3">
            <mat-form-field appearance="outline" floatLabel="never" class="custom-outline-mat-form-field">
              <input matInput type="text" class="text-center" readonly
                     placeholder="{{ isCurrent ? (compensationForm.controls['compensations'].controls[0].controls['effectiveDate'].value | date : 'MM/dd/yyyy') : 'Previous'}} Amount"
                     aria-label="Previous Amount" formControlName="previousCompensation" compensationCurrencyMask>
            </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" floatLabel="never" class="custom-outline-mat-form-field"  [ngClass]="{'editable': !locked && isCurrent}">
                <input matInput class="text-center" formControlName="newCompensation" compensationCurrencyMask
                       aria-label="New Compensation Amount"
                       placeholder="{{compensation.controls['effectiveDate'].value | date : 'MM/dd/yyyy'}} Amount">
                <mat-error *ngIf="isCurrent && showError(compensation,'newCompensation')">
                  New Compensation is Required
                </mat-error>
              </mat-form-field>
           </div>
            <div class="col-md-3">
            <mat-form-field appearance="outline" class="custom-outline-mat-form-field">
              <input matInput type="text" class="text-center" aria-label="Increase by dollar and percent"
                     readonly [value]="getIncrease(compensation.controls)">
            </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="tableMobile">
        <div class="rowMobile" *ngFor="let compensation of compensationForm.controls['compensations'].controls; first as isCurrent" [class.odd]="i % 2 === 0" [formGroup]="compensation">
          <div class="cell">
            <mat-form-field appearance="outline" floatLabel="never" [ngClass]="{'editable': (!locked && isCurrent) || isCurrent && effDateUnlocked}">
              <mat-label>Effective Date</mat-label>
              <input matInput class="text-center"
                     formControlName="effectiveDate"
                     [min]="minCompEffectiveDate"
                     [matDatepicker]="compEffectiveDatePicker"
                     name="effectiveDate"
                     aria-label="Effective Date"
                     placeholder="MM/dd/yyyy"
                     (click)="compEffectiveDatePicker.open();"
                     (focus)="compEffectiveDatePicker.open()"
                     minDateRangeValidator="minCompEffectiveDate">
              <mat-datepicker #compEffectiveDatePicker></mat-datepicker>
              <mat-error *ngIf="isCurrent && showDateError(compensation,'effectiveDate')">
                Required (format 'MM/d/yyyy')
              </mat-error>
              <mat-error *ngIf="isCurrent && invalidEffectiveDate(compensation,'effectiveDate', true)">
                Effective Date must be after previous Effective Date or Start Date.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field appearance="outline" floatLabel="never">
              <mat-label>Previous</mat-label>
              <input matInput type="text" class="text-center"
                     placeholder="{{ isCurrent ? (compensationForm.controls['compensations'].controls[0].controls['effectiveDate'].value | date : 'MM/dd/yyyy') : 'Previous'}} Amount"
                     aria-label="Previous Amount" formControlName="previousCompensation" compensationCurrencyMask>
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field appearance="outline" floatLabel="never" [ngClass]="{'editable': !locked && isCurrent}">
              <mat-label>New</mat-label>
              <input matInput class="text-center" formControlName="newCompensation" compensationCurrencyMask
                     aria-label="New Compensation Amount"
                     placeholder="{{compensation.controls['effectiveDate'].value | date : 'MM/dd/yyyy'}} Amount">
              <mat-error *ngIf="isCurrent && showError(compensation,'newCompensation')">
                New Compensation is Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="cell ">
            <mat-form-field appearance="outline">
              <mat-label>Increase</mat-label>
              <input matInput type="text" class="text-center" aria-label="Increase by dollar and percent"
                     readonly [value]="getIncrease(compensation.controls)">
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>

    <h5 class="subsection mt-4">Vacation Entitlement</h5>
    <div class="mat-elevation-z8 text-center">
      <div class="tableDesktop">
        <div class="row header-row">
          <div class="col-md-3"> Effective Date </div>
          <div class="col-md-3"> Previous </div>
          <div class="col-md-3"> New </div>
          <div class="col-md-3"> Vacation in $$ </div>
        </div>
        <div *ngFor="let vacation of compensationForm.controls['vacations'].controls; first as isCurrent">
          <div class="row body-row" [formGroup]="vacation" *ngIf="seeBothRows || (isCurrent && onlinerView)">
            <div class="col-md-3">
              <mat-form-field appearance="outline" floatLabel="never" class="custom-outline-mat-form-field" [ngClass]="{'editable': (!locked && isCurrent) || isCurrent && effDateUnlocked}">
                <input matInput class="text-center"
                       formControlName="effectiveDate"
                       [matDatepicker]="vacEffectiveDatePicker"
                       name="effectiveDate"
                       aria-label="Effective Date"
                       placeholder="MM/dd/yyyy"
                       (click)="vacEffectiveDatePicker.open();"
                       (focus)="vacEffectiveDatePicker.open()">
                <mat-datepicker #vacEffectiveDatePicker></mat-datepicker>
                <mat-error *ngIf="isCurrent && showDateError(vacation,'effectiveDate')">
                  Required (format 'MM/dd/yyyy')
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" floatLabel="never" class="custom-outline-mat-form-field">
                <input matInput type="text" class="text-center" formControlName="previousVacation"
                       placeholder="{{ isCurrent ? (compensationForm.controls['vacations'].controls[0].controls['effectiveDate'].value | date : 'MM/dd/yyyy') : 'Previous'}} weeks/year"
                       aria-label="weeks per year">
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" floatLabel="never" class="custom-outline-mat-form-field" [ngClass]="{'editable' : !locked && isCurrent}">
                <input matInput type="number" min="0" class="text-center" formControlName="newVacation" placeholder="weeks/year"
                       aria-label="weeks per year" step="1" oninput="this.value = Math.abs(this.value)">
                <mat-error *ngIf="isCurrent && showError(vacation,'newVacation')">New Vacation is Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="custom-outline-mat-form-field">
                <input matInput type="text" class="text-center" aria-label="weeks per year" readonly
                       value="${{getVacationDollarsPerSalaryText(compensationForm.controls, isCurrent)}}">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="tableMobile">
        <div class="rowMobile" *ngFor="let vacation of compensationForm.controls['vacations'].controls; first as isCurrent" [class.odd]="i % 2 === 0" [formGroup]="vacation">
          <div class="cell">
            <mat-form-field appearance="outline" floatLabel="never" [ngClass]="{'editable': (!locked && isCurrent) || isCurrent && effDateUnlocked}">
              <mat-label>Effective Date</mat-label>
              <input matInput class="text-center"
                     formControlName="effectiveDate"
                     [matDatepicker]="vacEffectiveDatePicker"
                     name="effectiveDate"
                     aria-label="Effective Date"
                     placeholder="MM/dd/yyyy"
                     (click)="vacEffectiveDatePicker.open();"
                     (focus)="vacEffectiveDatePicker.open()">
              <mat-datepicker #vacEffectiveDatePicker></mat-datepicker>
              <mat-error *ngIf="isCurrent && showDateError(vacation,'effectiveDate')">
                Required (format 'MM/dd/yyyy')
              </mat-error>
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field appearance="outline" floatLabel="never">
              <mat-label>Previous</mat-label>
              <input matInput type="text" class="text-center" formControlName="previousVacation"
                     placeholder="{{ isCurrent ? (compensationForm.controls['vacations'].controls[0].controls['effectiveDate'].value | date : 'MM/dd/yyyy') : 'Previous'}} weeks/year"
                     aria-label="weeks per year">
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field appearance="outline" floatLabel="never" [ngClass]="{'editable' : !locked && isCurrent}">
              <mat-label>New</mat-label>
              <input matInput type="number" min="0" class="text-center" formControlName="newVacation" placeholder="weeks/year"
                     aria-label="weeks per year" step="1" oninput="this.value = Math.abs(this.value)">
              <mat-error *ngIf="isCurrent && showError(vacation,'newVacation')">New Vacation is Required</mat-error>
            </mat-form-field>
          </div>
          <div class="cell ">
            <mat-form-field appearance="outline">
              <mat-label>Vacation in $$</mat-label>
              <input matInput type="text" class="text-center" aria-label="weeks per year" readonly
                     value="${{getVacationDollarsPerSalaryText(compensationForm.controls, isCurrent)}}">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <h5 class="subsection mt-4">Comments</h5>
    <div class="mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12 text-center pt-2">
          <mat-form-field appearance="outline" [ngClass]="{'editable' : canReject || canSubmit}" style="width:98%">
            <textarea *ngIf="canReject || canSubmit" matInput formControlName="comment" rows="5"> </textarea>
            <input *ngIf="!(canReject || canSubmit)" matInput readonly class="m-3 font-italic"
                   value="Commenting Disabled">
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <mat-expansion-panel class="no-border-radius" [expanded]="expandHistory">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h5>History : {{commentsAndAudits.comments.length}} comment(s)</h5>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="tableDesktop">
              <mat-list dense class="commment-list">
                <mat-list-item>
                  <div class="row">
                    <div class="col-sm-2 font-weight-bold custom-width">Date</div>
                    <div class="col-sm-3 font-weight-bold">Type</div>
                    <div class="col-sm-2 font-weight-bold">Comment By</div>
                    <div class="col-sm-5 font-weight-bold">Comment</div>
                  </div>
                </mat-list-item>
                <mat-list-item *ngFor="let comment of commentsAndAudits.comments" class="mat-list-item-details">
                  <div class="row">
                    <div class="col-sm-2 text custom-width">{{comment.commentDate | date : 'MM/dd/yyyy'}} </div>
                    <div class="col-sm-3 text">{{comment.commentType.name}}</div>
                    <div class="col-sm-2 text">{{getEmployeeName(comment.commentEmployee)}}</div>
                    <div class="col-sm-5 text">{{formatComment(comment.commentNote)}}</div>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>

            <div class="tableMobile">
              <div class="rowMobile commment-list" *ngFor="let comment of commentsAndAudits.comments" [class.odd]="i % 2 === 0">
                <div class="cell">
                  <span class="cell-header font-weight-bold">Date: </span>{{comment.commentDate | date : 'MM/dd/yyyy'}}
                </div>
                <div class="cell">
                  <span class="cell-header font-weight-bold">Type: </span>{{comment.commentType.name}}
                </div>
                <div class="cell">
                  <span class="cell-header font-weight-bold">Comment By: </span>{{getEmployeeName(comment.commentEmployee)}}
                </div>
                <div class="cell ">
                  <span class="cell-header font-weight-bold">Comment: </span>{{formatComment(comment.commentNote)}}
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>

    <div *ngIf="seeBothRows">
      <h5 class="subsection mt-4"></h5>
      <div class="mat-elevation-z8">
        <div class="row">
          <div class="col-sm-12">
            <mat-expansion-panel class="no-border-radius" expanded=true>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Audit</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="tableDesktop">
                <mat-list dense>
                  <mat-list-item mat-list-item>
                    <div class="row">
                      <div class="col-sm-2 font-weight-bold custom-width">Date</div>
                      <div class="col-sm-2 font-weight-bold">Status</div>
                      <div class="col-sm-2 font-weight-bold">User</div>
                      <div class="col-sm-6 font-weight-bold">Info</div>
                    </div>
                  </mat-list-item>
                  <mat-list-item mat-list-item class="mat-list-item-details" *ngFor="let audit of commentsAndAudits.audits">
                    <div class="row">
                      <div class="col-sm-2 text custom-width">{{audit.auditDate | date : 'MM/dd/yyyy'}}</div>
                      <div class="col-sm-2 text">{{audit.statusType.statusTypeName}}</div>
                      <div class="col-sm-2 text">{{getEmployeeName(audit.updateEmployee)}}</div>
                      <div class="col-sm-6 text">{{audit.info}}</div>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>

              <div class="tableMobile">
                <div class="rowMobile commment-list" *ngFor="let audit of commentsAndAudits.audits" [class.odd]="i % 2 === 0">
                  <div class="cell">
                    <span class="cell-header font-weight-bold">Date: </span>{{audit.auditDate | date : 'MM/dd/yyyy'}}
                  </div>
                  <div class="cell">
                    <span class="cell-header font-weight-bold">Status: </span>{{audit.statusType.statusTypeName}}
                  </div>
                  <div class="cell">
                    <span class="cell-header font-weight-bold">User: </span>{{getEmployeeName(audit.updateEmployee)}}
                  </div>
                  <div class="cell ">
                    <span class="cell-header font-weight-bold">Info: </span>{{audit.info}}
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="bccView">
      <h5 class="subsection mt-4">BCC</h5>
      <div class="mat-elevation-z8 form-group row m-0 mt-2">
        <div class="col-sm-12" [formGroup]="compensationForm.controls['bcc']">
          <mat-form-field appearance="outline" [ngClass]="{'editable': bccEditable }" class="onliner bccPadding custom-outline-mat-form-field">
            <input matInput type="text" placeholder="Onliner" aria-label="BCCAll" formControlName="bccOnliner"
                   [matAutocomplete]="onlinerauto">

            <mat-autocomplete autoActiveFirstOption #onlinerauto="matAutocomplete" panelWidth="280px"
                              [displayWith]="onlinerDisplay">
              <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
                {{ onlinerDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="row form-group mt-3 mr-3 ml-1">
    <button *ngIf="canSave" type="button" mat-stroked-button class="submit-btn"
            [disabled]="!compensationForm || !compensationForm.dirty || !compensationForm.valid || isSaving || isRejecting || isPrinting || isReviewing" (click)="confirmSaveStep()">
      <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>SAVE
    </button>
    <button *ngIf="canReject" type="button" mat-stroked-button class="reject-btn"
            [disabled]="!compensationForm || !compensationForm.valid || isRejecting || isSaving || isPrinting || isReviewing" (click)="confirmReject()">
      <span *ngIf="isRejecting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>REJECT
    </button>
    <button *ngIf="canSubmit" type="button" mat-stroked-button class="submit-btn"
            [disabled]="!compensationForm || !compensationForm.valid || isReviewing || isSaving || isRejecting || isPrinting" (click)="confirmReviewStep()">
      <span *ngIf="isReviewing"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>{{submitBtnText}}
    </button>
    <button *ngIf="canPrint" type="button" mat-stroked-button class="submit-btn"
            [disabled]="!compensationForm || !compensationForm.valid || isPrinting || isSaving || isRejecting || isReviewing" (click)="onPrintForm()">
      <span *ngIf="isPrinting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>PRINT
    </button>
  </div>
</div>

<ng-template #loading>
  <div class="loading m-auto"></div>
</ng-template>

