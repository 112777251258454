
<div class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>


  <div *ngIf="isVerticalLayout$ | async"
       class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img alt="Logo" class="select-none pointer-events-none w-32" src="/assets/images/OBSlogo_tiny.png" />
  </div>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1 h-full" [class.hidden]="false && mobileQuery && (isHorizontalLayout$ | async)">
    <div class="absolute h-full py-2 left-16 right-16 flex items-center" [class]="mobileQuery ? 'px-16' : 'px-40'">
      <a class="h-fit w-fit mx-auto" routerLink="/">
        <img class="select-none pointer-events-none" src="/assets/images/CompensationApproval-logo-602x50.png">
      </a>
    </div>
  </span>

  <div class="-mx-1 flex items-center">


    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation>
